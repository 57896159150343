<template>
	<b-card class="mb-1">
		<b-card-title class="d-flex align-items-center font-title">
			<div class="text-left" style="width: 50%">
				<feather-icon class="mr-1" icon="CloudIcon" size="18" />
				<span>环境</span>
			</div>
			<!--			<div class='text-right' style="width: 50%">-->
			<!--				<span style="font-size: 1.2rem">319 </span>-->
			<!--				<span class="position-relative" style="font-size: 0.9rem;bottom: .2rem">房间</span>-->
			<!--			</div>-->
		</b-card-title>

		<!-- 温度 -->
		<div v-for="(item, index) in chartNames" :key="index" :class="index === 0 ? '' : 'mt-2'">
			<div class="d-flex w-100">
				<div class="w-50">
					<span class="font-title">{{ item.title }} </span>
					<span class="text-muted font-small-2" v-html="item.unit"></span>
				</div>
				<div class="w-50 text-right">
					<span class="font-small-2">当前 </span>
					<span class="font-digit text-primary h5">{{ latestParams[item.key] || "N/A" }}</span>
				</div>
			</div>

			<div :id="'project-detail-environment-' + item.key" class="w-100" style="height: 9rem; margin-top: 1rem"></div>
		</div>
	</b-card>
</template>

<script>
	import { Line } from "@antv/g2plot";
	import { $themeColors } from "@themeConfig";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { routerParams } from "@/libs/utils/routerParams";

	export default {
		name: "Environment",
		props: {
			project_params: [Object, Array],
		},
		setup(props) {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				projectParams: computed(() => {
					return props.project_params || {};
				}),
				chartNames: computed(() => {
					let keys = [
						{ key: "OUTTEMP", title: "温度", unit: "℃" },
						{ key: "OUTHUMI", title: "湿度", unit: "%" },
						{ key: "OUTFZD", title: "辐照", unit: "W/m<sub>2</sub>" },
						{
							key: "OUTPM2.5",
							title: "PM2.5",
							unit: "μg/m<sup>3</sup>",
						},
					];
					if (parseInt(event.project_id) === 7) {
						keys = [
							{ key: "R315RT", title: "温度", unit: "℃" },
							{ key: "R315RH", title: "湿度", unit: "%" },
							{ key: "R315CO2", title: "CO2", unit: "ppm" },
							{
								key: "Room319_PM2.5",
								title: "PM2.5",
								unit: "μg/m<sup>3</sup>",
							},
						];
					}
					return keys;
				}),
				charts: {},
				latestParams: {},
			});
			watch(
				() => event.projectParams,
				(projectParams) => {
					const timeList = [
						"00:00",
						"01:00",
						"02:00",
						"03:00",
						"04:00",
						"05:00",
						"06:00",
						"07:00",
						"08:00",
						"09:00",
						"10:00",
						"11:00",
						"12:00",
						"13:00",
						"14:00",
						"15:00",
						"16:00",
						"17:00",
						"18:00",
						"19:00",
						"20:00",
						"21:00",
						"22:00",
						"23:00",
					];
					let latestParams = {};
					event.chartNames.map((name) => {
						const key = name.key;
						if (projectParams[key]) {
							let exist_hours = [];
							projectParams[key].map((item) => {
								exist_hours.push(item.time);
								if (item.value) {
									latestParams[key] = item.value;
								}
							});
							timeList.map((hour) => {
								if (!exist_hours.includes(hour)) {
									projectParams[key].push({
										name: key,
										value: null,
										time: hour,
									});
								}
							});
							event.charts[key].changeData(projectParams[key]);
						}
					});
					event.latestParams = latestParams;
				}
			);
			const chartInit = () => {
				event.chartNames.map((item) => {
					const line = new Line("project-detail-environment-" + item.key, {
						theme: {
							colors10: Object.values($themeColors),
						},
						data: [],
						color: () => {
							return $themeColors.primary;
						},
						padding: "auto",
						xField: "time",
						yField: "value",
						yAxis: {
							nice: true,
							grid: {
								line: {
									style: {
										stroke: "#82868b",
										lineWidth: 1,
										lineDash: [1, 6],
										strokeOpacity: 0.7,
									},
								},
							},
						},
						smooth: true,
						meta: {
							time: {
								alias: "时间",
							},
							value: {
								alias: "数值",
							},
						},
					});
					line.render();
					event.charts[item.key] = line;
				});
			};
			onMounted(() => {
				chartInit();
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
