<template>
	<div>
		<b-row>
			<!-- 性能指标 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="AnchorIcon" size="18" />
						性能指标
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<b-row>
							<b-col class="d-flex align-items-center" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-danger">
									<feather-icon icon="ZapIcon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-danger">25</span>
										<small>&nbsp;kgCO<sub>2</sub>/(m<sup>2</sup>&sdot;a)</small>
									</p>
									碳排放综合值
								</div>
							</b-col>

							<b-col class="d-flex align-items-center" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-success">
									<feather-icon icon="LifeBuoyIcon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-success">25</span>
										<small>&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small>
									</p>
									供暖年耗热量
								</div>
							</b-col>

							<b-col class="d-flex align-items-center mt-1" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-primary">
									<feather-icon icon="InboxIcon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-primary">25</span>
										<small>&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small>
									</p>
									供冷年耗冷量
								</div>
							</b-col>

							<b-col class="d-flex align-items-center mt-1" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-info">
									<feather-icon icon="WindIcon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-info">0.8</span>
									</p>
									建筑气密性
								</div>
							</b-col>

							<b-col class="d-flex align-items-center mt-1" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-secondary">
									<feather-icon icon="Volume2Icon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-secondary">35</span>
										<small>&nbsp;dB(A)</small>
									</p>
									室内噪声级
								</div>
							</b-col>

							<b-col class="d-flex align-items-center mt-1" cols="6">
								<b-avatar class="mr-1" rounded size="36" variant="light-warning">
									<feather-icon icon="SunIcon" size="18" />
								</b-avatar>
								<div>
									<p class="my-0">
										<span class="h4 font-digit text-warning">35</span>
										<small>&nbsp;kWp</small>
									</p>
									光伏容量
								</div>
							</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">围护结构</div>
						</div>

						<b-row>
							<b-col cols="6">屋面保温传热系数</b-col>
							<b-col cols="6"
								><span class="h4 font-digit text-success">0.06</span>
								<small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
							</b-col>

							<b-col class="mt-50" cols="6">外墙保温传热系数</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">0.1</span>
								<small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
							</b-col>

							<b-col class="mt-50" cols="6">地面保温传热系数</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">0.08</span>
								<small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
							</b-col>

							<b-col class="mt-50" cols="6">外窗传热系数</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">0.5</span>
								<small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
							</b-col>

							<b-col class="mt-50" cols="6">外窗太阳得热系数</b-col>
							<b-col class="mt-50" cols="6"><span class="h4 font-digit text-success">0.5</span></b-col>

							<b-col class="mt-50" cols="6">外门传热系数</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">1.0</span>
								<small>&nbsp;W/m<sup>2</sup>&sdot;K</small>
							</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">能源设备和系统</div>
						</div>

						<b-row>
							<b-col cols="6">制冷效率</b-col>
							<b-col cols="6"
								>APF
								<span class="h4 font-digit text-success">5.3</span>
								<small>&nbsp;</small>, IPLV
								<span class="h4 font-digit text-success">7.5</span>
								<small>&nbsp;</small>
							</b-col>

							<b-col class="mt-50" cols="6">制热效率</b-col>
							<b-col class="mt-50" cols="6"
								>COP
								<span class="h4 font-digit text-success">4.1</span>
								<small>&nbsp;</small>
							</b-col>

							<b-col class="mt-50" cols="6">新风热回收效率</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">79</span>
								<small>&nbsp;%</small>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 建筑模型 -->
			<b-col cols="6">
				<h1 class="font-title text-center">零碳建筑运行管理平台</h1>
				<!-- <b-embed
                    src="http://mmsvr.ccabrnzeb.com/?noCache=388a390952"
                    allowfullscreen
                    class="rounded"
                    scrolling="no"
                    type="iframe"
                    style="height: calc(60vh - 3rem); min-height: 578px"
                ></b-embed> -->
				<div id="modelBox" style="height: calc(60vh - 3rem); min-height: 578px"></div>
			</b-col>

			<!-- 标识状态 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="AwardIcon" size="18" />
						标识状态
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<b-row>
							<b-col class="text-center" cols="12">
								<b-img :src="require('@/assets/images/buildings/label_general.png')" fluid style="height: 7rem" />

								<h4 class="font-weight-bolder mt-1">零碳建筑标识证书</h4>
								<small>Certificate of Zero Carbon Building Label</small>
							</b-col>
						</b-row>

						<b-row>
							<b-col class="mt-50" cols="6">证书编号：***********</b-col>
							<b-col class="mt-50" cols="6">发证日期：****年**月**日</b-col>
							<b-col class="mt-50" cols="12">有效期限：****年**月**日-****年**月**日</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">项目概况</div>
						</div>

						<b-row>
							<b-col cols="12"> 建筑名称：***********</b-col>
							<b-col class="mt-50" cols="6">建筑面积：**** m<sup>2</sup></b-col>
							<b-col class="mt-50" cols="6">项目类型：****</b-col>
							<b-col class="mt-50" cols="6">测评阶段：****</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">评价指标</div>
						</div>

						<b-row>
							<b-col cols="6">碳排放强度指标</b-col>
							<b-col cols="6"
								><span class="h4 font-digit text-success">0</span>
								<small>&nbsp;kgCO<sub>2</sub>/(m<sup>2</sup>&sdot;a)</small>
							</b-col>

							<b-col class="mt-50" cols="6">能耗指标</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">10</span>
								<small>&nbsp;kWh/(m<sup>2</sup>&sdot;a)</small>
							</b-col>

							<b-col class="mt-50" cols="6">可再生能源利用率</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">41.60</span>
								<small>&nbsp;%</small>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<!-- 能源消耗 -->
			<b-col cols="4">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="ZapIcon" size="18" />
						能源消耗
					</b-card-title>

					<b-card-text style="height: calc(100% - 3rem)">
						<b-row class="h-100">
							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<vue-apex-charts :options="earningsChart.chartOptions" :series="earningsChart.series" height="100%" />
								<div class="d-flex justify-content-between">
									<div>
										<feather-icon class="mr-50 text-primary" icon="CircleIcon" size="16" />
										<span class="font-weight-bolder">Text</span>
									</div>
									<span>123</span>
								</div>
							</b-col>

							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<statistic-card-with-area-chart
									v-if="energyConsumption.analyticsData"
									:chart-data="energyConsumption.series"
									:statistic="kFormatter(energyConsumption.analyticsData.energy)"
									color="danger"
									icon="ZapIcon"
									statistic-title="耗电量 (kWh)"
								/>
								<div class="d-flex justify-content-between">
									<div>
										<feather-icon class="mr-50 text-danger" icon="CircleIcon" size="16" />
										<span class="font-weight-bolder">Text</span>
									</div>
									<span>123</span>
								</div>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 碳排放及碳交易 -->
			<b-col cols="4">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="CloudIcon" size="18" />
						碳排放及碳交易
					</b-card-title>

					<b-card-text style="height: calc(100% - 3rem)">
						<b-row class="h-100">
							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<statistic-card-with-area-chart
									v-if="carbonEmission.analyticsData"
									:chart-data="carbonEmission.series"
									:statistic="kFormatter(carbonEmission.analyticsData.carbon)"
									color="warning"
									icon="CloudIcon"
									statistic-title="碳排放 (kgCO2)"
								/>
								<div class="d-flex justify-content-between">
									<div>
										<feather-icon class="mr-50 text-warning" icon="CircleIcon" size="16" />
										<span class="font-weight-bolder">Text</span>
									</div>
									<span>123</span>
								</div>
							</b-col>
							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<vue-apex-charts id="revenue-report-chart" :options="revenueReport.chartOptions" :series="revenueReport.series" height="100%" type="bar" />
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 可再生能源 -->
			<b-col cols="4">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="SunIcon" size="18" />
						可再生能源
					</b-card-title>

					<b-card-text style="height: calc(100% - 3rem)">
						<b-row class="h-100">
							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<vue-apex-charts :options="renewableChart.chartOptions" :series="renewableChart.series" height="100%" />
								<div class="d-flex justify-content-between">
									<div>
										<feather-icon class="mr-50 text-success" icon="CircleIcon" size="16" />
										<span class="font-weight-bolder">Text</span>
									</div>
									<span>123</span>
								</div>
							</b-col>

							<b-col class="d-flex flex-column justify-content-between" cols="6">
								<statistic-card-with-area-chart
									v-if="energyGeneration.analyticsData"
									:chart-data="energyGeneration.series"
									:statistic="kFormatter(energyGeneration.analyticsData.energy)"
									color="success"
									icon="SunIcon"
									statistic-title="发电量 (kWh)"
								/>
								<div class="d-flex justify-content-between">
									<div>
										<feather-icon class="mr-50 text-success" icon="CircleIcon" size="16" />
										<span class="font-weight-bolder">Text</span>
									</div>
									<span>123</span>
								</div>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
	import { $themeColors } from "@themeConfig";
	import { kFormatter } from "@/@core/utils/filter";
	import { setupViewer } from "@/libs/forge/app";
	import VueApexCharts from "vue-apexcharts";
	import { onMounted, reactive, ref, toRefs, watch } from "@vue/composition-api";

	export default {
		name: "ZeroCarbon",
		components: {
			StatisticCardWithAreaChart,
			VueApexCharts,
		},
		setup() {
			const viewer = ref(null);
			const pos = ref(null);
			const tar = ref(null);
			const event = reactive({
				carbonEmission: {
					series: [
						{
							name: "Carbon",
							data: [0, -2, 1, -5, -2, -3, -1],
						},
					],
					analyticsData: {
						carbon: -1,
					},
				},
				energyConsumption: {
					series: [
						{
							name: "Energy",
							data: [18, 30, 32, 46, 28, 14, 25],
						},
					],
					analyticsData: {
						energy: 35,
					},
				},
				energyGeneration: {
					series: [
						{
							name: "Energy",
							data: [28, 40, 36, 36, 38, 44, 35],
						},
					],
					analyticsData: {
						energy: 26,
					},
				},
				revenueReport: {
					series: [
						{
							name: "买入",
							data: [95, 177, 284, 256, 105, 63],
						},
						{
							name: "卖出",
							data: [-68, -18, -72, -298, -178, -80],
						},
					],
					chartOptions: {
						chart: {
							stacked: false,
							type: "bar",
							toolbar: { show: false },
						},
						grid: {
							yaxis: {
								lines: { show: false },
							},
						},
						xaxis: {
							categories: ["一月", "二月", "三月", "四月", "五月", "六月"],
							labels: {
								style: {
									colors: "#808695",
								},
							},
							axisTicks: {
								show: false,
							},
							axisBorder: {
								show: false,
							},
						},
						legend: {
							show: true,
							position: "top",
							horizontalAlign: "right",
							labels: {
								colors: "#808695",
							},
						},
						dataLabels: {
							enabled: false,
						},
						colors: [$themeColors.primary, $themeColors.warning],
						plotOptions: {
							bar: {
								borderRadius: 6,
							},
							distributed: true,
						},
						yaxis: {
							labels: {
								style: {
									colors: "#808695",
								},
							},
							/* title: {
              text: "碳交易 (kgCO2)",
              style: {
                  color: "#808695",
                  fontWeight: "normal",
              }
          } */
						},
					},
				},
				renewableChart: {
					series: [53, 31, 16],
					chartOptions: {
						chart: {
							type: "donut",
							toolbar: {
								show: false,
							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: "bottom",
							labels: {
								colors: "#808695",
							},
						},

						labels: ["光伏", "风力", "其它"],
						stroke: { width: 0 },
						plotOptions: {
							pie: {
								donut: {
									labels: {
										show: true,
										name: {
											offsetY: 20,
										},
										value: {
											offsetY: -20,
											color: "#808695",
										},
										total: {
											show: true,
											label: "总计",
											color: "#808695",
										},
									},
								},
							},
						},
					},
				},
				earningsChart: {
					series: [53, 16, 31, 23],
					chartOptions: {
						chart: {
							type: "donut",
							toolbar: {
								show: false,
							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: "bottom",
							labels: {
								colors: "#808695",
							},
						},

						labels: ["空调", "照明", "插座", "动力"],
						stroke: { width: 0 },

						plotOptions: {
							pie: {
								donut: {
									labels: {
										show: true,
										name: {
											offsetY: 20,
										},
										value: {
											offsetY: -20,
											color: "#808695",
										},
										total: {
											show: true,
											label: "总计",
											color: "#808695",
										},
									},
								},
							},
						},
					},
				},
			});

			watch(viewer, (newVal) => {
				if (Autodesk) {
					newVal.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
						newVal.navigation.setView(new THREE.Vector3(-3740, -6452, 2286), new THREE.Vector3(-188, -398, -997));
						newVal.setNavigationLock(true);
						newVal.setNavigationLockSettings({
							orbit: true,
							zoom: true,
							roll: true,
						});
					});
					newVal.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, (e) => {
						pos.value = e.camera.position;
						tar.value = e.camera.target;
					});
				}
			});
			onMounted(() => {
				const box = document.getElementById("modelBox");
				setupViewer(box, viewer, "https://cabr-jsf-1255710621.cos.ap-beijing.myqcloud.com/cabr/experimental-building.svf", ["TurnTable", "MyAwesomeExtension", "SelectModel"]);
				const objResizeObserver = new ResizeObserver(function (entries) {
					const entry = entries[0];
					const cr = entry.contentRect;

					const canvasBox = document.getElementsByClassName("adsk-viewing-viewer");
					canvasBox[0].style.width = cr.width + "px";
					canvasBox[0].style.height = cr.height + "px";
				});

				objResizeObserver.observe(box);
			});
			return {
				...toRefs(event),
				kFormatter,
			};
		},
	};
</script>

<style>
	.adsk-viewing-viewer {
		background: none !important;
	}

	.homeViewWrapper {
		display: none !important;
	}
</style>
