<template>
	<b-card>
		<b-row>
			<b-col cols="4">
				<div class="d-flex align-items-center">
					<b-avatar class="mr-1" rounded size="42" variant="light-danger">
						<feather-icon icon="StarIcon" size="24" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">N/A</span>
							<span class="text-muted font-small-2">&nbsp;kWh/(m<sup>2</sup>&sdot;a)</span>
						</h4>
						<label class="my-0">单位面积能耗</label>
					</b-card-text>
				</div>
			</b-col>

			<b-col cols="4">
				<div class="d-flex align-items-center">
					<b-avatar class="mr-1" rounded size="42" variant="light-success">
						<feather-icon icon="StarIcon" size="24" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">N/A</span>
							<span class="text-muted font-small-2">&nbsp;kWh/(m<sup>2</sup>&sdot;a)</span>
						</h4>
						<label class="my-0">供暖年耗热量</label>
					</b-card-text>
				</div>
			</b-col>

			<b-col cols="4">
				<div class="d-flex align-items-center">
					<b-avatar class="mr-1" rounded size="42" variant="light-warning">
						<feather-icon icon="StarIcon" size="24" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">N/A</span>
							<span class="text-muted font-small-2">&nbsp;kWh/(m<sup>2</sup>&sdot;a)</span>
						</h4>
						<label class="my-0">供冷年耗冷量</label>
					</b-card-text>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	export default {
		name: "CenterBottom",
	};
</script>

<style scoped></style>
