<template>
	<b-card>
		<b-card-text class="font-small-2 text-muted">
			<span class="font-title mr-1">{{ projectInfo.city || "N/A" }}</span>
			<span>{{ projectInfo.lng ? projectInfo.lng.toFixed(2) : "N/A" }}° E, {{ projectInfo.lat ? projectInfo.lat.toFixed(2) : "N/A" }}° N</span>
		</b-card-text>
		<b-row>
			<b-col class="pr-0" cols="5">
				<div class="d-flex align-items-center mb-1">
					<b-avatar rounded size="36" style="margin-right: 0.5rem" variant="light-success">
						<feather-icon icon="ThermometerIcon" size="18" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">{{ projectParams["OUTTEMP"] ? projectParams["OUTTEMP"].toFixed(1) : "N/A" }} </span>
							<span class="text-muted font-small-2">℃</span>
						</h4>
						<label class="my-0"> 温度</label>
					</b-card-text>
				</div>
			</b-col>

			<b-col class="pr-0" cols="7">
				<div class="d-flex align-items-center mb-1">
					<b-avatar rounded size="36" style="margin-right: 0.5rem" variant="light-danger">
						<feather-icon icon="WindIcon" size="18" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">{{ projectParams["OUTDQY"] || "N/A" }} </span>
							<span class="text-muted font-small-2"> hPa</span>
						</h4>
						<label class="my-0">大气压 </label>
					</b-card-text>
				</div>
			</b-col>

			<b-col class="pr-0" cols="5">
				<div class="d-flex align-items-center">
					<b-avatar rounded size="36" style="margin-right: 0.5rem" variant="light-warning">
						<feather-icon icon="DropletIcon" size="18" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">{{ projectParams["OUTHUMI"] ? (projectParams["OUTHUMI"] / 100).toFixed(1) : "N/A" }} </span>
							<span class="text-muted font-small-2"> %</span>
						</h4>
						<label class="my-0"> 湿度</label>
					</b-card-text>
				</div>
			</b-col>

			<b-col class="pr-0" cols="7">
				<div class="d-flex align-items-center">
					<b-avatar rounded size="36" style="margin-right: 0.5rem" variant="light-info">
						<feather-icon icon="SunIcon" size="18" />
					</b-avatar>
					<b-card-text>
						<h4 class="my-0">
							<span class="font-digit">{{ projectParams["OUTFZD"] || "N/A" }}</span>
							<span class="text-muted font-small-2"> W/m<sup>2</sup></span>
						</h4>
						<label class="my-0"> 辐照</label>
					</b-card-text>
				</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	import { computed, reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "ParamsRight",
		props: {
			project_params: [Object, Array],
			project_info: [Object],
		},
		setup(props) {
			const event = reactive({
				projectParams: computed(() => {
					return (
						props.project_params || {
							OUTTEMP: null,
							"OUTPM2.5": null,
							OUTHUMI: null,
							OUTFZD: null,
						}
					);
				}),
				projectInfo: computed(() => {
					return props.project_info || { city: null, lng: null, lat: null };
				}),
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
