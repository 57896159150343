<template>
	<b-card class="mb-1" style="height: 314px">
		<b-row>
			<b-col cols="5">
				<b-card-title class="d-flex align-items-center font-title">
					<feather-icon class="mr-1" icon="CheckCircleIcon" size="18" />
					优化
				</b-card-title>
				<div>
					<span class="font-title">负荷预测</span>
					<span class="text-muted font-small-2"> kW</span>
				</div>
				<div id="project-detail-optimization" style="height: 12rem; margin-top: 1rem"></div>
				<h4 class="mt-50">
					<label>今日预计最大负荷</label>
					<span class="font-digit ml-1">{{ predictionMax.value || "0" }}</span>
					<span class="font-small-2 text-muted"> kW @ </span>
					<span class="font-small-2">{{ predictionMax.time || "" }}</span>
				</h4>
			</b-col>
			<b-col cols="7" style="height: 270px; overflow-y: scroll; overflow-x: hidden">
				<b-tabs>
					<b-tab title="夏季">
						<b-table :fields="tableColumns" :items="strategySummer" class="w-100 h-100" small striped>
							<template #cell(param_name)="data">
								<div style="width: 10.5rem">
									{{ data.item.param_name }}
								</div>
							</template>
							<template #cell(change)="data">
								<feather-icon
									v-if="data.item.cur_value !== data.item.rec_value"
									:class="data.item.cur_value > data.item.rec_value ? 'text-danger' : 'text-success'"
									:icon="data.item.cur_value > data.item.rec_value ? 'ArrowDownIcon' : 'ArrowUpIcon'"
								/>
								<feather-icon v-else class="text-primary" icon="MinusIcon" />
							</template>
						</b-table>
					</b-tab>

					<b-tab active title="冬季">
						<b-table :fields="tableColumns" :items="strategyWinter" class="w-100 h-100" small striped>
							<template #cell(param_name)="data">
								<div style="width: 10.5rem">
									{{ data.item.param_name }}
								</div>
							</template>
							<template #cell(change)="data">
								<feather-icon
									v-if="data.item.cur_value !== data.item.rec_value"
									:class="data.item.cur_value > data.item.rec_value ? 'text-danger' : 'text-success'"
									:icon="data.item.cur_value > data.item.rec_value ? 'ArrowDownIcon' : 'ArrowUpIcon'"
								/>
								<feather-icon v-else class="text-primary" icon="MinusIcon" />
							</template>
						</b-table>
					</b-tab>

					<b-tab title="过渡季"></b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	import { Area } from "@antv/g2plot";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { $themeColors } from "@themeConfig";
	import { routerParams } from "@/libs/utils/routerParams";

	export default {
		name: "Optimization",
		props: {
			project_optimization: [Object],
		},
		setup(props) {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				projectOptimization: computed(() => {
					return props.project_optimization || {};
				}),
				predictionMax: { egname: "", value: 0, time: "" },
				energy_chart: null,
				tableColumns: [
					{
						label: "节能运行参数",
						key: "param_name",
					},
					{
						label: "当前值",
						key: "cur_value",
					},
					{
						label: "推荐值",
						key: "rec_value",
					},
					{
						label: "变化",
						key: "change",
					},
				],
				strategySummer: [
					{
						param_name: "冷冻水出水温度 (℃)",
						cur_value: "7.2",
						rec_value: "8",
						change: "",
					},
					{
						param_name: "制冷机开启台数",
						cur_value: "1",
						rec_value: "1",
						change: "",
					},
					{
						param_name: "冷冻水泵频率 (Hz)",
						cur_value: "45",
						rec_value: "42",
						change: "",
					},
					{
						param_name: "冷却水泵频率 (Hz)",
						cur_value: "47",
						rec_value: "42",
						change: "",
					},
					{
						param_name: "冷却塔开启台数",
						cur_value: "1",
						rec_value: "1",
						change: "",
					},
					{
						param_name: "冷却塔风机频率 (Hz)",
						cur_value: "35",
						rec_value: "30",
						change: "",
					},
					{
						param_name: "开机时间",
						cur_value: "8:00",
						rec_value: "8:30",
						change: "",
					},
					{
						param_name: "关机时间",
						cur_value: "18:00",
						rec_value: "17:30",
						change: "",
					},
				],
				strategyWinter: [
					{
						param_name: "冷凝侧出水温度 (℃)",
						cur_value: "45",
						rec_value: "42",
						change: "",
					},
					{
						param_name: "冷凝侧回水温度 (℃)",
						cur_value: "40",
						rec_value: "38",
						change: "",
					},
					{
						param_name: "蒸发侧出水温度 (℃)",
						cur_value: "10",
						rec_value: "9",
						change: "",
					},
					{
						param_name: "蒸发侧回水温度 (℃)",
						cur_value: "12",
						rec_value: "13",
						change: "",
					},
					{
						param_name: "冷却水泵频率 (Hz)",
						cur_value: "45",
						rec_value: "43.5",
						change: "",
					},
					{
						param_name: "室内侧水泵频率 (Hz)",
						cur_value: "50",
						rec_value: "48",
						change: "",
					},
					{
						param_name: "冷机开启台数",
						cur_value: "2",
						rec_value: "2",
						change: "",
					},
					{
						param_name: "开机时间",
						cur_value: "8:00",
						rec_value: "7:50",
						change: "",
					},
					{
						param_name: "关机时间",
						cur_value: "18:00",
						rec_value: "17:30",
						change: "",
					},
				],
			});
			watch(
				() => event.projectOptimization,
				(value) => {
					if (value) {
						let data = [...value.energy_list, ...value.prediction_list];
						data = data.filter((item) => {
							return ["预测值", "实际值"].includes(item.egname);
						});
						event.energy_chart.changeData(data);
						const list = value.prediction_list;
						let max = { egname: "", value: 0, time: "" };
						if (list) {
							list.map((item) => {
								if (item.value >= max.value) {
									max = item;
								}
							});
						}
						event.predictionMax = max;
					}
				}
			);
			const chartInit = () => {
				const area = new Area("project-detail-optimization", {
					theme: {
						colors10: Object.values($themeColors),
					},
					legend: true,
					data: [],
					xField: "time",
					yField: "value",
					seriesField: "egname",
					isStack: false,
					xAxis: {
						range: [0, 1],
						tickCount: 5,
						line: {
							style: {
								lineWidth: 0.5,
							},
						},
					},
					yAxis: {
						nice: true,
						grid: {
							line: {
								style: {
									stroke: "#82868b",
									lineWidth: 1,
									lineDash: [1, 6],
									strokeOpacity: 0.7,
								},
							},
						},
					},
					areaStyle: () => {
						return {
							fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
						};
					},
					meta: {
						value: {
							alias: "数值",
							formatter: (value) => {
								return Math.round(value * 100) / 100;
							},
						},
					},
				});
				area.render();
				event.energy_chart = area;
			};
			onMounted(() => {
				chartInit();
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
