<template>
	<div>
		<b-tabs fill>
			<b-tab active>
				<template #title>
					<feather-icon icon="CameraIcon" />
					<span>照片</span>
				</template>
				<b-card-text class="rounded shadow overflow-hidden" style="margin-bottom: 2rem">
					<b-carousel id="carousel-fade" controls fade indicators>
						<b-carousel-slide
							v-for="(carouselItem, carouselIndex) in projectInfo.images"
							:key="'carousel_' + carouselIndex"
							:style="'background: url(' + carouselItem + ');'"
							style="background-size: cover; background-position: center center; width: 100%; height: 417px"
						>
						</b-carousel-slide>
					</b-carousel>
				</b-card-text>
			</b-tab>
			<b-tab v-if="projectInfo.vr">
				<template #title>
					<feather-icon icon="ApertureIcon" />
					<span>全景</span>
				</template>

				<b-card-text class="rounded shadow" style="height: 417px; margin-bottom: 2rem">
					<b-embed :src="iframeSrc(projectInfo.vr)" allowfullscreen class="rounded" scrolling="no" type="iframe"></b-embed>
				</b-card-text>
			</b-tab>
			<b-tab>
				<template #title>
					<feather-icon icon="MapPinIcon" />
					<span>位置</span>
				</template>

				<b-card-text id="project-detail-map" class="position-relative rounded shadow overflow-hidden" style="height: 417px; margin-bottom: 2rem"></b-card-text>
			</b-tab>
			<b-tab>
				<template #title>
					<feather-icon icon="InfoIcon" />
					<span>简介</span>
				</template>
				<b-card style="height: 417px; overflow-y: auto">
					<b-card-text v-if="projectInfo">
						{{ projectInfo.description }}
					</b-card-text>
				</b-card>
			</b-tab>
			<b-tab>
				<template #title>
					<feather-icon icon="SendIcon" />
					<span>联系</span>
				</template>
				<b-card style="height: 417px">
					<b-card-text>
						<p v-if="projectInfo.telephone" class="d-flex align-items-center"><feather-icon icon="PhoneIcon" />&nbsp;{{ projectInfo.telephone }}</p>
						<p v-if="projectInfo.fax" class="d-flex align-items-center"><feather-icon icon="PrinterIcon" />&nbsp;{{ projectInfo.fax }}</p>
						<p v-if="projectInfo.email" class="d-flex align-items-center"><feather-icon icon="MailIcon" />&nbsp;{{ projectInfo.email }}</p>
						<p v-if="projectInfo.address" class="d-flex align-items-center"><feather-icon icon="MapPinIcon" />&nbsp;{{ projectInfo.address }}</p>
					</b-card-text>
				</b-card>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
	import { computed, reactive, toRefs, watch } from "@vue/composition-api";
	import store from "@/store";
	import { PointLayer, Scene } from "@antv/l7";
	import { GaodeMap } from "@antv/l7-maps";
	import { iframeSrc } from "@/libs/utils/iframeSrc";

	export default {
		name: "Center",
		props: {
			project_info: [Object],
		},
		setup(props) {
			const event = reactive({
				projectInfo: computed(() => {
					return props.project_info;
				}),
				map_style: computed(() => {
					const skin = store.state.appConfig.layout.skin;
					return skin === "dark" ? "dark" : "light";
				}),
				selected: 0,
				scene: null,
				selected_layer: null,
				project_list_layer: null,
				popup_layer: null,
			});
			/**
			 * 监听地图主题，更新地图主题
			 */
			watch(
				() => event.map_style,
				(map_style) => {
					event.scene.setMapStyle(map_style);
				}
			);
			watch(
				() => event.projectInfo,
				(value) => {
					if (value) {
						map_init();
					}
				}
			);

			/* 地图组件初始化 */
			const map_init = () => {
				event.scene = new Scene({
					id: "project-detail-map",
					map: new GaodeMap({
						pitch: 0,
						style: event.map_style,
						center: [event.projectInfo.lng, event.projectInfo.lat],
						token: "aeb928d0fa255249be59173a8d5fa016",
						zoom: 7,
						minZoom: 4,
						maxZoom: 16,
					}),
				});
				event.scene.on("loaded", () => {
					let project_list = [];
					project_list.push(event.projectInfo);
					const pointLayer = new PointLayer()
						.source(project_list, {
							parser: {
								type: "json",
								x: "lng",
								y: "lat",
							},
						})
						.shape("circle")
						.animate(true)
						.size(30)
						.color("#28c76f")
						.style({
							opacity: 0.3,
						});
					event.scene.addLayer(pointLayer);
				});
			};

			return {
				...toRefs(event),
				iframeSrc,
			};
		},
	};
</script>

<style scoped></style>
