<template>
	<div>
		<b-row>
			<!-- 得分总览 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="ActivityIcon" size="18" />
						得分总览
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_6" class="w-100" style="height: 280px"></div>

						<div class="divider">
							<div class="divider-text">分项得分</div>
						</div>

						<b-row>
							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-primary">
									<feather-icon icon="CheckSquareIcon" size="18" />
								</b-avatar>
								<div class="mt-50">总分</div>
								<h3 class="font-digit my-0">565</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-danger">
									<feather-icon icon="WindIcon" size="18" />
								</b-avatar>
								<div class="mt-50">控制项</div>
								<h3 class="font-digit my-0">95</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-success">
									<feather-icon icon="DropletIcon" size="18" />
								</b-avatar>
								<div class="mt-50">安全耐久</div>
								<h3 class="font-digit my-0">72</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-warning">
									<feather-icon icon="CoffeeIcon" size="18" />
								</b-avatar>
								<div class="mt-50">健康舒适</div>
								<h3 class="font-digit my-0">84</h3>
							</b-col>
						</b-row>

						<b-row class="mt-50">
							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-secondary">
									<feather-icon icon="DribbbleIcon" size="18" />
								</b-avatar>
								<div class="mt-50">生活便利</div>
								<h3 class="font-digit my-0">89</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-info">
									<feather-icon icon="BookOpenIcon" size="18" />
								</b-avatar>
								<div class="mt-50">资源节约</div>
								<h3 class="font-digit my-0">64</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-warning">
									<feather-icon icon="CompassIcon" size="18" />
								</b-avatar>
								<div class="mt-50">环境宜居</div>
								<h3 class="font-digit my-0">75</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-success">
									<feather-icon icon="Share2Icon" size="18" />
								</b-avatar>
								<div class="mt-50">提高与创新</div>
								<h3 class="font-digit my-0">86</h3>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 建筑模型 -->
			<b-col cols="6">
				<h1 class="font-title text-center">绿色建筑运行管理平台</h1>
				<b-embed allowfullscreen class="rounded" scrolling="no" src="http://mmsvr.ccabrnzeb.com/?noCache=388a390952" style="height: calc(60vh - 3rem); min-height: 578px" type="iframe"></b-embed>
			</b-col>

			<!-- 标识状态 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="AwardIcon" size="18" />
						标识状态
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<b-row>
							<b-col class="text-center" cols="12">
								<b-img :src="require('@/assets/images/buildings/label_green_building.png')" fluid style="height: 7rem" />
								<p class="h4 text-success mt-50">
									<span class="mr-1">&#9733;</span>
									<span class="mr-1">&#9733;</span>
									<span>&#9733;</span>
								</p>
								<h4 class="font-weight-bolder">三星级绿色建筑标识证书</h4>
								<small>Certificate of Green Building Label</small>
							</b-col>
						</b-row>

						<b-row>
							<b-col class="mt-50" cols="6">证书编号：***********</b-col>
							<b-col class="mt-50" cols="6">发证日期：****年**月**日</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">项目概况</div>
						</div>

						<b-row>
							<b-col cols="12"> 项目名称：***********</b-col>
							<b-col class="mt-50" cols="6">建筑面积：**** m<sup>2</sup></b-col>
							<b-col class="mt-50" cols="6">建筑类型： ****</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">评价指标</div>
						</div>

						<b-row>
							<b-col cols="6">建筑节能率</b-col>
							<b-col cols="6"><span class="h4 font-digit text-success">71.78</span> %</b-col>

							<b-col class="mt-50" cols="6">可再生能源利用率</b-col>
							<b-col class="mt-50" cols="6"><span class="h4 font-digit text-success">54.80</span> %</b-col>

							<b-col class="mt-50" cols="6">非传统水源利用率</b-col>
							<b-col class="mt-50" cols="6"><span class="h4 font-digit text-success">41.60</span> %</b-col>

							<b-col class="mt-50" cols="6">住区绿地率</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success">8.56</span>
								%
							</b-col>

							<b-col class="mt-50" cols="6">可再循环建组材料使用率</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success">10.89</span>
								%
							</b-col>

							<b-col class="mt-50" cols="6">室内空气污染物浓度</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success"></span>
								符合国标GB50325
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<!-- 安全耐久 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="WindIcon" size="18" />
						安全耐久
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_8" class="w-100" style="height: 100%"></div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 健康舒适 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="DropletIcon" size="18" />
						健康舒适
					</b-card-title>

					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<!-- <statistic-card-with-area-chart
                            v-if="quarterlySales.analyticsData"
                            :chart-data="quarterlySales.series"
                            :statistic="
                                kFormatter(quarterlySales.analyticsData.sales)
                            "
                            color="warning"
                            icon="DropletIcon"
                            statistic-title="Quarterly Sales"
                        /> -->
						<div id="chart_7" class="w-100" style="height: 100%"></div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 生活便利 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="CoffeeIcon" size="18" />
						生活便利
					</b-card-title>

					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_9" class="w-100" style="height: 100%"></div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 资源节约 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="DribbbleIcon" size="18" />
						资源节约
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_10" class="w-100" style="height: 100%"></div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 环境宜居 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="BookOpenIcon" size="18" />
						环境宜居
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_11" class="w-100" style="height: 100%"></div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 提高与创新 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="CompassIcon" size="18" />
						提高与创新
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_5" class="w-100 mb-50" style="height: 70%"></div>

						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-warning" icon="CircleIcon" size="16" />
								<span>暖通空调系统节能率</span>
							</div>
							<span>60 <small>%</small></span>
						</div>
						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-success" icon="CircleIcon" size="16" />
								<span>场地绿容率</span>
							</div>
							<span>3.5</span>
						</div>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { Bar, Gauge, Liquid, Pie, Radar, RadialBar, RingProgress, Rose } from "@antv/g2plot";
	import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
	import { kFormatter } from "@/@core/utils/filter";

	export default {
		name: "Green",
		components: {
			StatisticCardWithAreaChart,
		},
		data() {
			return {
				subscribersGained: {
					series: [
						{
							name: "Subscribers",
							data: [28, 40, 36, 36, 38, 44, 35],
						},
					],
					analyticsData: {
						subscribers: 92600,
					},
				},
				quarterlySales: {
					series: [
						{
							name: "Sales",
							data: [10, 15, 7, 12, 3, 16],
						},
					],
					analyticsData: {
						sales: "36%",
					},
				},
				gaugeOptions: {
					radius: 0.9,
					range: {
						color: "#30BF78",
					},
					indicator: {
						pointer: {
							style: {
								stroke: "#D0D0D0",
							},
						},
						pin: {
							style: {
								stroke: "#D0D0D0",
							},
						},
					},
					statistic: {
						content: {
							style: {
								fontSize: 16,
							},
							formatter: ({ percent }) => `得分: ${(percent * 100).toFixed(0)}`,
						},
					},
					gaugeStyle: {
						lineCap: "round",
					},
				},
			};
		},
		methods: {
			kFormatter,
			chart_init_1() {
				const data = [
					{ name: "室内空气品质", star: 95 },
					{ name: "水质", star: 72 },
					{ name: "声环境与光环境", star: 84 },
					{ name: "室内热湿环境", star: 89 },
				];
				const radarPlot = new Radar("chart_1", {
					data: data.map((d) => ({ ...d, star: Math.sqrt(d.star) })),
					xField: "name",
					yField: "star",
					appendPadding: [0, 10, 0, 10],
					meta: {
						star: {
							alias: "得分",
							min: 0,
							nice: true,
						},
					},
					xAxis: {
						tickLine: null,
					},
					yAxis: {
						label: false,
						grid: {
							alternateColor: "rgba(0, 0, 0, 0.04)",
						},
					},
					// 开启辅助点
					point: {
						size: 2,
					},
					area: {},
				});
				radarPlot.render();
			},
			chart_init_2() {
				const gauge = new Gauge("chart_2", {
					percent: 0.65,
					...this.gaugeOptions,
				});

				gauge.render();
			},
			chart_init_3() {
				const ringProgress = new RingProgress("chart_3", {
					autoFit: true,
					percent: 0.8,
					color: ["#F4664A", "#E8EDF3"],
				});

				ringProgress.render();
			},
			chart_init_4() {
				const liquidPlot = new Liquid("chart_4", {
					percent: 0.25,
					outline: {
						border: 4,
						distance: 1,
					},
					wave: {
						length: 128,
					},
				});
				liquidPlot.render();
			},
			chart_init_5() {
				const gauge = new Gauge("chart_5", {
					percent: 0.86,
					type: "meter",
					innerRadius: 0.75,
					range: {
						ticks: [0, 1 / 3, 2 / 3, 1],
						color: ["#F4664A", "#FAAD14", "#30BF78"],
					},
					indicator: {
						pointer: {
							style: {
								stroke: "#D0D0D0",
							},
						},
						pin: {
							style: {
								stroke: "#D0D0D0",
							},
						},
					},
					statistic: {
						content: {
							offsetY: -10,
							style: {
								fontSize: "1.5rem",
								lineHeight: "1.5rem",
								color: "#808695",
							},
						},
					},
				});
				gauge.render();
			},
			chart_init_6() {
				const data = [
					{ name: "环境宜居", star: 64 },
					{ name: "资源节约", star: 89 },
					{ name: "生活便利", star: 84 },
					{ name: "健康舒适", star: 72 },
					{ name: "安全耐久", star: 95 },
				];

				const bar = new RadialBar("chart_6", {
					data,
					xField: "name",
					yField: "star",
					maxAngle: 270, //最大旋转角度,
					radius: 1,
					innerRadius: 0.3,
					barStyle: {
						lineCap: "round",
					},
				});

				bar.render();
			},
			chart_init_7() {
				const data = [
					{ type: "室内空气品质", value: 27 },
					{ type: "水质", value: 25 },
					{ type: "声环境与光环境", value: 18 },
					{ type: "室内热湿环境", value: 14 },
				];

				const piePlot = new Pie("chart_7", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 1,
					innerRadius: 0.6,
					pieStyle: {
						lineWidth: 0,
					},
					label: {
						type: "inner",
						offset: "-50%",
						content: "{value}",
						style: {
							textAlign: "center",
							fontSize: 14,
						},
					},
					interactions: [{ type: "element-selected" }, { type: "element-active" }],
					statistic: {
						title: {
							formatter: () => "合计",
							offsetY: -8,
							style: {
								fontSize: "0.9rem",
								color: "",
							},
						},
						content: {
							offsetY: -4,
							style: {
								fontSize: "1.5rem",
								fontWeight: "normal",
								color: "",
							},
						},
						/* title: false,
                  content: {
                      style: {
                          whiteSpace: "pre-wrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                      },
                      content: "84",
                  }, */
					},
					legend: {
						itemName: {
							style: {
								fill: "#808695",
							},
						},
					},
				});

				piePlot.render();
			},
			chart_init_8() {
				const data = [
					{ type: "安全", value: 27 },
					{ type: "耐久", value: 25 },
				];

				const piePlot = new Pie("chart_8", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 1,
					innerRadius: 0.6,
					pieStyle: {
						lineWidth: 0,
					},
					label: {
						type: "inner",
						offset: "-50%",
						content: "{value}",
						style: {
							textAlign: "center",
							fontSize: 14,
						},
					},
					interactions: [{ type: "element-selected" }, { type: "element-active" }],
					statistic: {
						title: {
							formatter: () => "合计",
							offsetY: -8,
							style: {
								fontSize: "0.9rem",
								color: "",
							},
						},
						content: {
							offsetY: -4,
							style: {
								fontSize: "1.5rem",
								fontWeight: "normal",
								color: "",
							},
						},
					},
					legend: {
						itemName: {
							style: {
								fill: "#808695",
							},
						},
					},
				});

				piePlot.render();
			},
			chart_init_9() {
				const data = [
					{ type: "出行与无障碍", value: 27 },
					{ type: "服务设施", value: 15 },
					{ type: "智慧运行", value: 25 },
					{ type: "物业管理", value: 35 },
				];

				const bar = new Bar("chart_9", {
					data,
					xField: "value",
					yField: "type",
					xAxis: {
						label: null,
						grid: null,
					},
					seriesField: "type",
					legend: false,
					label: {
						position: "right",
						style: {
							fill: "#808695",
							opacity: 0.8,
						},
					},
				});

				bar.render();
			},
			chart_init_10() {
				const data = [
					{ type: "节地与土地利用", value: 15 },
					{ type: "节能与能源利用", value: 20 },
					{ type: "节水与水资源利用", value: 17 },
					{ type: "节材与绿色建材", value: 25 },
				];

				const rosePlot = new Rose("chart_10", {
					data,
					xField: "type",
					yField: "value",
					seriesField: "type",
					radius: 0.8,
					legend: false,
					label: {
						position: "top",
						style: {
							fill: "#808695",
							opacity: 0.8,
						},
					},
					sectorStyle: {
						lineWidth: 0,
					},
				});

				rosePlot.render();
			},
			chart_init_11() {
				const data = [
					{ type: "场地生态与景观", value: 27 },
					{ type: "室外物理环境", value: 20 },
				];

				const piePlot = new Pie("chart_11", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 1,
					innerRadius: 0.6,
					pieStyle: {
						lineWidth: 0,
					},
					label: {
						type: "inner",
						offset: "-50%",
						content: "{value}",
						style: {
							textAlign: "center",
							fontSize: 14,
						},
					},
					interactions: [{ type: "element-selected" }, { type: "element-active" }],
					statistic: {
						title: {
							formatter: () => "合计",
							offsetY: -8,
							style: {
								fontSize: "0.9rem",
								color: "",
							},
						},
						content: {
							offsetY: -4,
							style: {
								fontSize: "1.5rem",
								fontWeight: "normal",
								color: "",
							},
						},
					},
					legend: {
						position: "bottom",
						itemName: {
							style: {
								fill: "#808695",
							},
						},
					},
				});

				piePlot.render();
			},
		},
		mounted() {
			//this.chart_init_1();
			//this.chart_init_2();
			//this.chart_init_3();
			//this.chart_init_4();
			this.chart_init_5();
			this.chart_init_6();
			this.chart_init_7();
			this.chart_init_8();
			this.chart_init_9();
			this.chart_init_10();
			this.chart_init_11();
		},
	};
</script>

<style scoped></style>
