<template>
	<b-card class="mb-1" style="height: 858px">
		<b-card-title class="d-flex align-items-center font-title">
			<feather-icon class="mr-1" icon="ZapIcon" size="18" />
			能耗
		</b-card-title>
		<b-row v-for="(energyItem, energyKey, energyIndex) in projectEnergy" :key="energyKey" :class="energyIndex % 4 === 4 ? '' : 'mb-1'">
			<b-col cols="6">
				<h4 class="my-0">
					<span class="font-digit">{{ energyItem.value }}</span>
					<span class="text-muted font-small-2">&nbsp;kWh</span>
				</h4>
				<label class="my-0">
					{{ energyItem.label }}
				</label>
			</b-col>
			<b-col cols="6">
				<b-card-text class="text-muted font-small-3">
					<div>
						{{ energyItem.last }}
						<span class="font-small-2">kWh</span>
					</div>

					<label class="d-flex align-items-center my-0">
						<span class="text-muted">
							{{ energyItem.last_label }}
							{{ energyItem.label !== "日" ? "" : "全天" }}
							&nbsp;
						</span>
						<span :class="energyItem.percent > 0 ? 'text-danger' : 'text-success'"> {{ energyItem.percent }}% </span>
						<feather-icon :class="energyItem.percent > 0 ? 'text-danger' : 'text-success'" :icon="energyItem.percent > 0 ? 'TrendingUpIcon' : 'TrendingDownIcon'" />
					</label>
				</b-card-text>
			</b-col>
		</b-row>

		<hr class="my-2" />

		<div class="d-flex align-items-center">
			<b-avatar class="mr-2" size="48" variant="light-success">
				<h3 class="font-digit text-success my-0">CO<sub>2</sub></h3>
			</b-avatar>
			<b-card-text>
				<h3 class="my-0">
					<span class="font-digit">{{ co2.today }}</span>
					<span class="text-muted font-small-2">&nbsp;kgCO<sub>2</sub></span>
				</h3>
				<b-card-text class="my-0"> 今日碳排放</b-card-text>
				<b-card-text class="text-muted mb-0 d-flex align-items-center font-small-3" style="margin-top: 0.5rem">
					<span>昨日 {{ co2.last }} </span>
					<span>&nbsp;kgCO<sub>2</sub>&nbsp;</span>
					<div v-if="projectEnergy.day" :class="projectEnergy.day.percent > 0 ? 'text-danger' : 'text-success'" class="d-flex align-items-center">
						<span>{{ projectEnergy.day.percent }}%</span>
						<feather-icon :icon="projectEnergy.day.percent > 0 ? 'TrendingUpIcon' : 'TrendingDownIcon'" />
					</div>
				</b-card-text>
			</b-card-text>
		</div>

		<hr class="my-2" />

		<!-- 分类能耗 -->
		<div class="mb-1">
			<span class="font-title">分类能耗</span>
			<span class="text-muted font-small-2">&nbsp;kWh</span>
			<div id="project-detail-energy-pie" style="height: 12rem"></div>
		</div>

		<!-- 逐月能耗 -->
		<div>
			<div>
				<span class="font-title">逐月能耗</span>
				<span class="text-muted font-small-2">&nbsp;kWh</span>
			</div>
			<div id="project-detail-energy-column" style="height: 11rem; margin-top: 1rem"></div>
		</div>
	</b-card>
</template>

<script>
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { routerParams } from "@/libs/utils/routerParams";
	import { $themeColors } from "@themeConfig";
	import { Column, Pie, RadialBar } from "@antv/g2plot";
	import store from "@/store";

	export default {
		name: "Energy",
		props: {
			project_energy: [Object],
		},
		setup(props) {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				projectEnergy: computed(() => {
					return props.project_energy;
				}),
				co2_coefficient: computed(() => {
					const project_data = store.state.projects.selected.project_data;
					return project_data && project_data.co2_coefficient ? project_data.co2_coefficient : 0.8843;
				}),
				co2: {
					today: 0,
					last: 0,
				},
				piePlot: null,
				radialPlot: null,
				columnPlot: null,
			});

			watch(
				() => event.projectEnergy,
				(projectEnergy) => {
					event.co2.today = Math.round(parseFloat(projectEnergy.day.value) * event.co2_coefficient * 100) / 100;
					event.co2.last = Math.round(parseFloat(projectEnergy.day.last) * event.co2_coefficient * 100) / 100;
					const energy_list = projectEnergy.year.list;
					let energy_type_list = {};
					let energy_month_list = {};
					energy_list.map((energyItem) => {
						energyItem["time"] = energyItem["time"].replace("-01 00:00:00", "");
						if (!energy_type_list[energyItem.egid]) {
							energy_type_list[energyItem.egid] = {
								type: "",
								value: 0,
							};
						}
						if (!energy_month_list[energyItem.time]) {
							energy_month_list[energyItem.time] = {
								time: "",
								value: 0,
							};
						}
						energy_type_list[energyItem.egid]["type"] = energyItem.egname;
						energy_type_list[energyItem.egid]["value"] += parseInt(energyItem.value);
						energy_month_list[energyItem.time]["time"] = energyItem.time;
						energy_month_list[energyItem.time]["value"] += parseInt(energyItem.value);
					});
					event.piePlot.changeData(Object.values(energy_type_list));
					event.columnPlot.changeData(Object.values(energy_month_list));
					// const chartData = [
					// 	{
					// 		label: projectEnergy.day.last_label,
					// 		value: projectEnergy.day.last,
					// 	},
					// 	{
					// 		label: projectEnergy.day.label,
					// 		value: projectEnergy.day.value,
					// 	},
					// ];

					// event.radialPlot.changeData(chartData)
				}
			);

			const energyChartInit = () => {
				const radialPlot = new RadialBar("energy-chart-day", {
					theme: {
						colors10: Object.values($themeColors),
					},
					data: [],
					xField: "label",
					yField: "value",
					// maxAngle: 90, //最大旋转角度,
					radius: 0.8,
					innerRadius: 0.2,
					maxBarWidth: 8,
					maxAngle: 210,
					barStyle: {
						lineCap: "round",
						fillOpacity: 1,
						lineWidth: 1,
					},
					barBackground: {
						style: {
							fill: "#000",
							fillOpacity: 0.1,
						},
					},
					colorField: "label",
					// color: ['#00cfe8','#28c76f'],
					tooltip: {
						formatter: (datum) => {
							return {
								name: datum.label,
								value: datum.value + " kWh",
							};
						},
					},
				});
				radialPlot.render();
				event.radialPlot = radialPlot;
			};
			const pieChartInit = () => {
				const piePlot = new Pie("project-detail-energy-pie", {
					theme: {
						colors10: Object.values($themeColors),
					},
					appendPadding: 10,
					data: [],
					angleField: "value",
					colorField: "type",
					radius: 1,
					innerRadius: 0.6,
					pieStyle: {
						lineWidth: 0,
					},
					label: {
						type: "inner",
						offset: "-50%",
						autoRotate: false,
						style: {
							textAlign: "center",
							fill: "#c5c8ce",
						},
						formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
					},
					legend: {
						position: "left",
						itemName: {
							style: {
								fill: "#808695",
							},
						},
					},
					statistic: {
						title: {
							formatter: () => "合计",
							offsetY: -8,
							style: {
								fontSize: "0.9rem",
								color: "",
							},
						},
						content: {
							offsetY: -4,
							style: {
								fontSize: "1.286rem",
								fontFamily: "LcdD",
								fontWeight: "normal",
								color: "",
							},
						},
					},
					// 添加 中心统计文本 交互
					interactions: [
						{
							type: "element-selected",
						},
						{
							type: "element-highlight",
						},
						{
							type: "pie-statistic-active",
							cfg: {
								start: [
									{
										trigger: "element:mouseenter",
										action: "pie-statistic:change",
									},
									{
										trigger: "legend-item:mouseenter",
										action: "pie-statistic:change",
									},
								],
								end: [
									{
										trigger: "element:mouseleave",
										action: "pie-statistic:reset",
									},
									{
										trigger: "legend-item:mouseleave",
										action: "pie-statistic:reset",
									},
								],
							},
						},
					],
				});
				piePlot.render();
				event.piePlot = piePlot;
			};
			const columnChartInit = () => {
				const columnPlot = new Column("project-detail-energy-column", {
					theme: {
						colors10: Object.values($themeColors),
					},
					data: [],
					xField: "time",
					yField: "value",
					color: () => {
						return $themeColors["primary"];
					},
					label: {
						// 可手动配置 label 数据标签位置
						position: "top", // 'top', 'bottom', 'middle',
						// 配置样式
						style: {
							fill: "#808695",
							opacity: 0.8,
						},
					},
					xAxis: {
						label: {
							autoHide: true,
							autoRotate: false,
						},
					},
					yAxis: {
						grid: null,
						label: null,
					},
					meta: {
						time: {
							alias: "月份",
						},
						value: {
							alias: "耗电量",
						},
					},
				});

				columnPlot.render();
				event.columnPlot = columnPlot;
			};
			onMounted(() => {
				// energyChartInit()
				pieChartInit();
				columnChartInit();
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
