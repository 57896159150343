import { render, staticRenderFns } from "./CenterBottom.vue?vue&type=template&id=f49bde42&scoped=true&"
import script from "./CenterBottom.vue?vue&type=script&lang=js&"
export * from "./CenterBottom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f49bde42",
  null
  
)

export default component.exports