<template>
  <div class="position-relative">
    <page-title></page-title>

    <div class="mt-1 position-relative rounded overflow-hidden">
      <b-carousel id="carousel-fade" :interval="5000">
        <b-carousel-slide v-for="(image, index) in projectInfo.images" :key="index" class="h-100">
          <template v-slot:img>
            <img :src="image" alt="项目图片" class="d-block img-fluid w-100"
                 style="height: calc(90vh - 7rem) !important"/>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="position-fixed m-0" style="bottom: 2rem; z-index: 1;width: calc(100% - 4rem)">
        <b-row>
          <b-col cols="4">
            <b-row cols="2">
              <b-col class="mb-1 pr-0">
                <b-card no-body style="height: 100%; opacity: 0.8">
                  <div class="project-params">
                    <div class="text-center" style="flex: 4">
                      <feather-icon icon="SquareIcon" size="28"></feather-icon>
                    </div>
                    <div class="mes" style="flex: 8">
                      <h4 class="mb-50">建筑面积</h4>
                      <p class="mb-50 text-truncate" style="font-size: 1.2rem">
                        <span class="text-primary">{{ projectInfo.building_area }} m<sup>2</sup></span>
                        <small>（{{ projectInfo.building_level }}层）</small>
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col class="mb-1 pr-0">
                <b-card no-body style="height: 100%; opacity: 0.8">
                  <div class="project-params">
                    <div class="text-center" style="flex: 4">
                      <feather-icon icon="HomeIcon" size="28"></feather-icon>
                    </div>
                    <div class="mes" style="flex: 8">
                      <h4 class="mb-50">建筑类型</h4>
                      <p class="mb-50" style="font-size: 1.2rem">
                        <span class="text-primary">{{ projectInfo.building_type }}</span>
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col class="mb-1 pr-0">
                <b-card no-body style="height: 100%; opacity: 0.8">
                  <div class="project-params">
                    <div class="text-center" style="flex: 4">
                      <feather-icon icon="ClockIcon" size="28"></feather-icon>
                    </div>
                    <div class="mes" style="flex: 8">
                      <h4 class="mb-50">建成时间</h4>
                      <p class="mb-50" style="font-size: 1.3rem">
                        <span class="text-primary">{{ projectInfo.building_time }}</span>
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col class="mb-1 pr-0">
                <b-card no-body style="height: 100%; opacity: 0.8">
                  <div class="project-params">
                    <div class="text-center" style="flex: 4">
                      <feather-icon icon="ActivityIcon" size="28"></feather-icon>
                    </div>
                    <div class="mes" style="flex: 8">
                      <h4 class="mb-50">今日用电量</h4>
                      <p class="mb-50" style="font-size: 1.2rem">
                        <span class="text-primary">{{
                            projectInfo.energy_d ? projectInfo.energy_d + " kwh" : "N/A"
                          }}</span>
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="mb-1" cols="8">
            <b-card style="height: 100%; opacity: 0.8; overflow: hidden">
              <div>
                <h4>建筑简介：</h4>
                <p class="mt-1" style="text-indent: 2rem; font-size: 1.1rem">
                  {{ projectInfo.description }}
                </p>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>



  </div>
</template>

<script>
import {routerParams} from "@/libs/utils/routerParams";
import {computed, onMounted, reactive, toRefs} from "@vue/composition-api";
import store from "@/store";
import {showToast} from "@/libs/utils/showToast";
import PageTitle from "@/views/layout/PageTitle";

export default {
  name: "ProjectDetailSimple",
  components: {
    PageTitle,
  },
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      projectInfo: {
        images: [],
      },
    });
    const getProjectInfo = () => {
      const project_id = event.project_id;
      store
          .dispatch("projects/fetchProject", project_id)
          .then((response) => {
            event.projectInfo = response.data;
            console.log("event.projectInfo", event.projectInfo);
          })
          .catch((error) => {
            showToast(error);
          });
    };
    onMounted(() => {
      getProjectInfo();
    });
    return {
      ...toRefs(event),
    };
  },
};
</script>

<style lang="scss" scoped>
.project-params {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
}
</style>
