<template>
	<b-card style="height: 658px">
		<b-card-title class="d-flex align-items-center font-title">
			<feather-icon class="mr-1" icon="HardDriveIcon" size="18" />
			设备
		</b-card-title>

		<b-row>
			<b-col class="text-center" cols="3">
				<b-avatar size="30" variant="light-danger">
					<feather-icon icon="ServerIcon" size="15" />
				</b-avatar>
				<label>制冷机</label>
				<h4 class="my-0">
					<span class="font-digit">1 </span>
					<span class="text-muted font-small-2">/3</span>
				</h4>
			</b-col>

			<b-col class="text-center" cols="3">
				<b-avatar size="30" variant="light-primary">
					<feather-icon icon="ToggleLeftIcon" size="15" />
				</b-avatar>
				<label>冷冻泵</label>
				<h4 class="my-0">
					<span class="font-digit">1 </span>
					<span class="text-muted font-small-2">/4</span>
				</h4>
			</b-col>

			<b-col class="text-center" cols="3">
				<b-avatar size="30" variant="light-info">
					<feather-icon icon="ToggleRightIcon" size="15" />
				</b-avatar>
				<label>冷却泵</label>
				<h4 class="my-0">
					<span class="font-digit">1 </span>
					<span class="text-muted font-small-2">/4</span>
				</h4>
			</b-col>

			<b-col class="text-center" cols="3">
				<b-avatar size="30" variant="light-success">
					<feather-icon icon="DatabaseIcon" size="15" />
				</b-avatar>
				<label>冷却塔</label>
				<h4 class="my-0">
					<span class="font-digit">1 </span>
					<span class="text-muted font-small-2">/3</span>
				</h4>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col cols="5">
				<div id="project-detail-equipment-liquid" style="height: 6rem"></div>
			</b-col>
			<b-col class="d-flex align-items-center" cols="7">
				<div style="width: 100%">
					<h4 class="d-flex justify-content-between align-items-end">
						<label>供冷量</label>
						<div>
							<span class="font-digit">350.1</span>
							<span class="text-muted font-small-2">&nbsp;kWh</span>
						</div>
					</h4>
					<h4 class="my-0 d-flex justify-content-between align-items-end">
						<label>耗电量</label>
						<div>
							<span class="font-digit">62.5</span>
							<span class="text-muted font-small-2">&nbsp;kWh</span>
						</div>
					</h4>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="6">
				<label>负荷率 <span class="font-small-2 text-muted">%</span></label>
				<b-progress class="progress-bar-primary mb-2" max="100" show-value value="65" variant="primary" />

				<label>冷冻泵频率 <span class="font-small-2 text-muted">Hz</span></label>
				<b-progress class="progress-bar-primary mb-2" max="50" show-value value="45" variant="primary" />

				<label>冷冻水温度 <span class="font-small-2 text-muted">℃</span></label>
				<b-row>
					<b-col cols="6">
						<b-avatar badge="供" badge-variant="primary" size="36" variant="light-primary">
							<h4 class="font-digit text-primary">7.2</h4>
						</b-avatar>
					</b-col>
					<b-col cols="6">
						<b-avatar badge="回" badge-variant="primary" size="36" variant="light-primary">
							<h4 class="font-digit text-primary">11.5</h4>
						</b-avatar>
					</b-col>
				</b-row>
			</b-col>

			<b-col cols="6">
				<label>冷却水温度 <span class="font-small-2 text-muted">℃</span></label>
				<b-row class="mb-2">
					<b-col cols="6">
						<b-avatar badge="供" badge-variant="info" size="36" variant="light-info">
							<h4 class="font-digit text-info">32.5</h4>
						</b-avatar>
					</b-col>
					<b-col cols="6">
						<b-avatar badge="回" badge-variant="info" size="36" variant="light-info">
							<h4 class="font-digit text-info">27.4</h4>
						</b-avatar>
					</b-col>
				</b-row>

				<label>冷却泵频率<span class="font-small-2 text-muted">&nbsp;Hz</span></label>
				<b-progress class="progress-bar-info mb-2" max="50" show-value value="47" variant="info" />

				<label>冷却塔风机频率<span class="font-small-2 text-muted">&nbsp;Hz</span></label>
				<b-progress class="progress-bar-info" max="50" show-value value="35" variant="info" />
			</b-col>
		</b-row>

		<!-- 制冷/热量 -->
		<div class="mt-3">
			<span class="font-title">供冷/热量</span>
			<span class="text-muted font-small-2">&nbsp;kWh</span>
			<div id="project-detail-equipment-column" style="height: 10rem"></div>
		</div>
	</b-card>
</template>

<script>
	import { Column, Liquid } from "@antv/g2plot";
	import { $themeColors } from "@themeConfig";
	import { onMounted } from "@vue/composition-api";

	export default {
		name: "Equipment",
		setup() {
			const liquidChartInit = () => {
				const liquidPlot = new Liquid(document.getElementById("project-detail-equipment-liquid"), {
					theme: {
						colors10: Object.values($themeColors),
					},
					color: () => {
						return $themeColors.primary;
					},
					percent: 0.56,
					radius: 1,
					statistic: {
						title: {
							formatter: () => "COP",
							style: ({ percent }) => ({
								fontSize: "0.9rem",
								fill: percent >= 0.65 ? "white" : "",
							}),
						},
						content: {
							style: ({ percent }) => ({
								fontFamily: "LcdD",
								fontSize: "1.286rem",
								fill: percent >= 0.5 ? "white" : "",
							}),
							customHtml: (container, view, { percent }) => {
								const text = `${(percent * 10).toFixed(1)}`;
								return `<div>${text}</div>`;
							},
						},
					},
				});
				liquidPlot.render();
			};
			const columnChartInit = () => {
				const stackedColumnPlot = new Column("project-detail-equipment-column", {
					theme: {
						colors10: Object.values($themeColors),
					},
					data: [
						{
							name: "供冷量",
							month: "一月",
							energy: 0,
						},
						{
							name: "供冷量",
							month: "二月",
							energy: 0,
						},
						{
							name: "供冷量",
							month: "三月",
							energy: 100,
						},
						{
							name: "供冷量",
							month: "四月",
							energy: 300,
						},
						{
							name: "供冷量",
							month: "五月",
							energy: 1200,
						},
						{
							name: "供冷量",
							month: "六月",
							energy: 1500,
						},
						{
							name: "供冷量",
							month: "七月",
							energy: 2000,
						},
						{
							name: "供冷量",
							month: "八月",
							energy: 1800,
						},
						{
							name: "供冷量",
							month: "九月",
							energy: 1200,
						},
						{
							name: "供冷量",
							month: "十月",
							energy: 300,
						},
						{
							name: "供冷量",
							month: "十一月",
							energy: 0,
						},
						{
							name: "供冷量",
							month: "十二月",
							energy: 0,
						},
						{
							name: "供热量",
							month: "一月",
							energy: 1500,
						},
						{
							name: "供热量",
							month: "二月",
							energy: 1200,
						},
						{
							name: "供热量",
							month: "三月",
							energy: 800,
						},
						{
							name: "供热量",
							month: "四月",
							energy: 100,
						},
						{
							name: "供热量",
							month: "五月",
							energy: 0,
						},
						{
							name: "供热量",
							month: "六月",
							energy: 0,
						},
						{
							name: "供热量",
							month: "七月",
							energy: 0,
						},
						{
							name: "供热量",
							month: "八月",
							energy: 0,
						},
						{
							name: "供热量",
							month: "九月",
							energy: 100,
						},
						{
							name: "供热量",
							month: "十月",
							energy: 500,
						},
						{
							name: "供热量",
							month: "十一月",
							energy: 1000,
						},
						{
							name: "供热量",
							month: "十二月",
							energy: 1200,
						},
					],
					isGroup: true,
					xField: "month",
					yField: "energy",
					seriesField: "name",
					/** 设置颜色 */
					color: [$themeColors.warning, $themeColors.primary],
					label: false,
					xAxis: {},
					yAxis: {
						grid: null,
						label: null,
					},
					legend: {
						position: "bottom",
					},
				});

				stackedColumnPlot.render();
			};

			onMounted(() => {
				liquidChartInit();
				columnChartInit();
			});
		},
	};
</script>

<style scoped></style>
