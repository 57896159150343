<template>
	<div>
		<component :is="homepage_type + 'Detail'"></component>
	</div>
</template>

<script>
	import store from "@/store";
	import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
	import { routerParams } from "@/libs/utils/routerParams";
	import simpleDetail from "./components/simple";
	import complexDetail from "./components/complex";
	import greenDetail from "../../construction/green";
	import healthDetail from "../../construction/health";
	import nearlyZeroDetail from "../../construction/nearly_zero";
	import zeroCarbonDetail from "../../construction/zero_carbon";
	import dashboardDetail from "./components/dashboard";
	import jsfDetail from "@/views/front/jsf35/homepage";

	export default {
		name: "ProjectDetail",
		components: {
			simpleDetail,
			complexDetail,
			dashboardDetail,
			greenDetail,
			healthDetail,
			nearlyZeroDetail,
			zeroCarbonDetail,
			jsfDetail,
		},
		setup() {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				homepage_type: computed(() => {
					let storage = JSON.parse(localStorage.getItem("project-selected"));
					return store.state.projects.selected.homepage_type || storage.homepage_type;
				}),
			});

			onMounted(() => {});
			return {
				...toRefs(event),
			};
		},
		// beforeRouteLeave(to,from,next){
		//   document.body.removeAttribute('data-theme')
		//   next()
		// }
	};
</script>

<style lang="scss"></style>
